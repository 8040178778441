export default {
    // url: "https://testapi.yakoafricassur.com/enov/medias/",
    url: "https://api.yakoafricassur.com/enov/medias/",
    localurl:"http://api.yakoafrica.local/enov/medias/",
    url_root:"'https://testapi.yakoafricassur.com",
    secret: "XSTN7cLsgAyfBhbGJj6MeHxeHE7fEdDF4JM438qK",
    algo: "HS256",
    }


//"http://192.168.11.163/enovapi/public/enov/medias/", 